import { Routes } from '@dtx-company/true-common/src/constants/routes'
import { getHeap } from '@dtx-company/true-common/src/utils/heap'
import { throttle } from 'throttle-debounce'
import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'

const scrollTrackingBuckets: number[] = [15, 25, 35, 45, 50, 55, 65, 75, 85, 95, 100]
const scrollTrackingBucketsMap: {
  [eventKey: string]: {
    [trackingKey: string]: boolean
  }
} = {}

/**
 * useHeapScrollTracking is a hook that sends the percentage scrolled through a page to the Heap API.
 */
export function useHeapScrollTracking(key?: string): void {
  const eventKey = 'Scroll depth on ' + key
  const router = useRouter()
  const [prevScrollLevel, setPrevScrollLevel] = useState<number>(0)
  const onScroll = throttle(200, () => {
    const scrollHeight = document.documentElement.scrollHeight - window.innerHeight
    const scrollDistance =
      window.pageYOffset ||
      ((document.documentElement || document.body.parentNode || document.body) as HTMLElement)
        .scrollTop
    const scrollPercentage = Math.trunc((100 * scrollDistance) / scrollHeight)

    // Pick closest percentage in the bucket.
    const idx = scrollTrackingBuckets.findIndex(elem => elem >= scrollPercentage)
    let nextScrollLevel = scrollTrackingBuckets[idx]
    const nextLowestScrollLevel = idx != 0 ? scrollTrackingBuckets[idx - 1] : null
    if (
      idx != 0 &&
      !!nextLowestScrollLevel &&
      nextScrollLevel - scrollPercentage > scrollPercentage - nextLowestScrollLevel
    ) {
      nextScrollLevel = nextLowestScrollLevel
    }

    // Only track an event if the scroll percentage changed from the previous
    // one.
    if (nextScrollLevel !== prevScrollLevel) {
      const hasScrollPositionBeenRecorded = scrollTrackingBucketsMap?.[eventKey]?.[nextScrollLevel]
      setPrevScrollLevel(nextScrollLevel)
      if (hasScrollPositionBeenRecorded) {
        return
      }
      if (nextScrollLevel) {
        scrollTrackingBucketsMap[eventKey] = {
          ...scrollTrackingBucketsMap[eventKey],
          [nextScrollLevel]: true
        }
      }
      router.pathname !== Routes.FLOWPAGE &&
        getHeap()?.track(eventKey, { percent: nextScrollLevel })
    }
  })
  useEffect(() => {
    if (!key) return // Don't track unknown pages.
    document.addEventListener('scroll', onScroll)
    return () => {
      onScroll.cancel()
      document.removeEventListener('scroll', onScroll)
    }
  }, [onScroll, key]) // These values don't change so it's equivalent to []
}
