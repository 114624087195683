export const events = {
  //mobile
  Platform_MobileBottomNavBar_Clicked_Tab: 'Platform_MobileBottomNavBar_Clicked_Tab',
  Platform_MobileBottomNavBar_Clicked_Create_Fab: 'Platform_MobileBottomNavBar_Clicked_Create_Fab',
  Platform_Mobile_AssetManagmentMenu_Clicked_Option:
    'Platform_Mobile_AssetManagmentMenu_Clicked_Option',
  Platform_Mobile_AssetManagmentMenu_Dismissed_Sheet:
    'Platform_Mobile_AssetManagmentMenu_Dismissed_Sheet',
  Platform_Mobile_AssetManagmentMenu_Clicked_CreateFolder:
    'Platform_Mobile_AssetManagmentMenu_Clicked_CreateFolder',
  Platform_Mobile_AssetManagmentMenu_Clicked_GetMoreStorage:
    'Platform_Mobile_AssetManagmentMenu_Clicked_GetMoreStorage',
  Platform_TopPageNav_Clicked_Open_FileExplorerSheet:
    'Platform_TopPageNav_Clicked_Open_FileExplorerSheet',
  Platform_MobileMoreOptionsSheet_Clicked_Option: 'Platform_MobileMoreOptionsSheet_Clicked_Option',
  Platform_MobileMoreOptionsSheet_Opened_ProfileSection:
    'Platform_MobileMoreOptionsSheet_Clicked_ProfileSection',
  Platform_MobileMoreOptionsSheet_Clicked_Upgrade:
    'Platform_MobileMoreOptionsSheet_Clicked_Upgrade',
  Platform_Mobile_AssetCreationMenu_Clicked_Option:
    'Platform_Mobile_AssetCreationMenu_Clicked_Option',
  Platform_MobileMoreOptionsSheet_ProfileMenu_Clicked_MenuItem:
    'Platform_MobileMoreOptionsSheet_ProfileMenu_Clicked_MenuItem',
  Platform_MobileMoreOptionsSheet_ProfileMenu_Clicked_EditProfilePicture:
    'Platform_MobileMoreOptionsSheet_ProfileMenu_Clicked_EditProfilePicture',
  Platform_MobileMoreOptionsSheet_ProfileMenu_Clicked_Upgrade:
    'Platform_MobileMoreOptionsSheet_ProfileMenu_Clicked_Upgrade',
  Platform_MobileMoreOptionsSheet_ProfileMenu_Dismissed_Sheet:
    'Platform_MobileMoreOptionsSheet_ProfileMenu_Dismissed_Sheet',
  Platform_MobileMoreOptionsSheet_TopBar_Clicked_Item:
    'Platform_MobileMoreOptionsSheet_TopBar_Clicked_Item',
  Platform_MobilePageNav_Clicked_BackButton: 'Platform_MobilePageNav_Clicked_BackButton',
  Flowcode_Clicked_KebabMenu: 'Flowcode_Clicked_KebabMenu',
  Flowcode_CompleteState_Clicked_Share: 'Flowcode_CompleteState_Clicked_Share',

  //desktop
  Platform_DesktopNavigationRail_Clicked_MenuItem:
    'Platform_DesktopNavigationRail_Clicked_MenuItem',
  Platform_Desktop_AssetManagmentMenu_Clicked_Option:
    'Platform_Desktop_AssetManagmentMenu_Clicked_Option',
  Platform_Desktop_AssetManagmentMenu_Clicked_CreateFolder:
    'Platform_Desktop_AssetManagmentMenu_Clicked_CreateFolder',
  Platform_Desktop_AssetManagmentMenu_Clicked_GetMoreStorage:
    'Platform_Desktop_AssetManagmentMenu_Clicked_GetMoreStorage',
  Platform_Desktop_LeftSideDrawer_Clicked_ToggleOpen:
    'Platform_Desktop_LeftSideDrawer_Clicked_ToggleOpen',
  Platform_Desktop_LeftSideDrawer_Hovered_OpenDrawer:
    'Platform_Desktop_LeftSideDrawer_Hovered_OpenDrawer',
  Platform_Desktop_TopNav_Clicked_ProfilePicture: 'Platform_Desktop_TopNav_Clicked_ProfilePicture',
  Platform_Desktop_TopNav_Clicked_IconButton: 'Platform_Desktop_TopNav_Clicked_IconButton',
  Platform_Desktop_TopNav_Clicked_Upgrade: 'Platform_Desktop_TopNav_Clicked_Upgrade',
  Platform_Desktop_AssetCreationMenu_Clicked_Option:
    'Platform_Desktop_AssetCreationMenu_Clicked_Option',
  Platform_DesktopNavigationRail_Clicked_AssetCreation_Fab:
    'Platform_DesktopNavigationRail_Clicked_AssetCreation_Fab',
  Platform_Desktop_TopNav_ProfileMenu_Clicked_MenuItem:
    '  Platform_Desktop_TopNav_ProfileMenu_Clicked_MenuItem',
  Platform_Desktop_TopNav_ProfileMenu_Clicked_EditProfilePicture:
    'Platform_Desktop_TopNav_ProfileMenu_Clicked_EditProfilePicture',
  Platform_Desktop_TopNav_CartEmptyState_Clicked_VisitPrintStoreButton:
    'Platform_Desktop_TopNav_CartEmptyState_Clicked_VisitPrintStoreButton',
  Platform_Creation_UnsavedWorkWarningModal_Clicked_Button:
    'Platform_Creation_UnsavedWorkWarningModal_Clicked_Button',
  Platform_Flowteams_Clicked_TopActionButton: 'Platform_Flowteams_Clicked_TopActionButton',
  Platform_Flowteams_Clicked_FAQButton: 'Platform_Flowteams_Clicked_FAQButton',
  Platform_Flowteams_PageHeader_Clicked_Tab: 'Platform_Flowteams_PageHeader_Clicked_Tab',
  Platform_FeatureLandingPage_Clicked_Button: 'Platform_FeatureLandingPage_Clicked_Button',
  Platform_CodeCreation_Clicked_CancelButton: 'Platform_CodeCreation_Clicked_CancelButton',
  Platform_PageCreation_Clicked_CancelButton: 'Platform_PageCreation_Clicked_CancelButton',
  Global_Navigation_MobileNav_Clicked_ConnectWithUs:
    'Global_Navigation_MobileNav_Clicked_ConnectWithUs',
  Global_Navigation_MobileNav_Clicked_SignUp: 'Global_Navigation_MobileNav_Clicked_SignUp',
  Global_Navigation_MobileNav_Clicked_SignIn: 'Global_Navigation_MobileNav_Clicked_SignIn',
  Platform_Mobile_TopNav_Clicked_Edit_FolderName: 'Platform_Mobile_TopNav_Clicked_Edit_FolderName',
  Platform_Mobile_TopNav_Blurred_FolderNameInput: 'Platform_Mobile_TopNav_Blurred_FolderNameInput',
  Platform_Mobile_TopNav_Clicked_SaveFolderName: 'Platform_Mobile_TopNav_Clicked_SaveNewFolderName',
  Platform_ProfilePictureUpload_Clicked_Button: 'Platform_ProfilePictureUpload_Clicked_Button',
  Platform_PlansUpgradeDialog_Clicked_PlanTile:
    'Platform_Desktop_PlansUpgradeDialog_Clicked_PlanTile',
  Platform_PlansUpgradeDialog_Dismissed_Dialog: 'Platform_PlansUpgradeDialog_Dismissed_Dialog',
  Platform_SideNav_Clicked_ExploreAPI: 'Platform_SideNav_Clicked_ExploreAPI',
  Platform_SideNav_Clicked_DismissDeveloperAPI: 'Platform_SideNav_Clicked_DismissDeveloperAPI'
}

export default events
