export default {
  Global_MarketingNav_Clicked_DropdownLink: 'Global_MarketingNav_Clicked_DropdownLink',
  Global_MarketingNav_Clicked_DropdownMenu: 'Global_MarketingNav_Clicked_DropdownMenu',
  Global_MarketingNav_Clicked_NavLink: 'Global_MarketingNav_Clicked_NavLink',
  Global_MarketingNav_Clicked_SignupButton: 'Global_MarketingNav_Clicked_SignupButton',
  Global_MarketingNav_Clicked_SigninButton: 'Global_MarketingNav_Clicked_SigninButton',
  Global_MarketingNav_Clicked_DashboardButton: 'Global_MarketingNav_Clicked_DashboardButton',

  Global_PlatformNav_Clicked_NavLink: 'Global_PlatformNav_Clicked_NavLink',
  Global_PlatformNav_Clicked_DropdownMenu: 'Global_PlatformNav_Clicked_DropdownMenu',
  Global_PlatformNav_Clicked_DropdownMenuItem: 'Global_PlatformNav_Clicked_DropdownMenuItem',

  Global_Navigation_Clicked_HamburgerMenu: 'Global_Navigation_Clicked_HamburgerMenu',
  Global_Navigation_Closed_MobileNav: 'Global_Navigation_Closed_MobileNav',
  Global_Navigation_Clicked_FlowcodeLogo: 'Global_Navigation_Clicked_FlowcodeLogo',
  Global_Navigation_Clicked_DomainsLocked: 'Global_Navigation_Clicked_DomainsLocked'
}
