import { Routes } from '../constants/routes'

export const LOGGED_OUT_ONLY_PATHS = [Routes.SIGN_IN, Routes.SIGN_UP, Routes.SSO_LOGIN]

export const PLATFORM_NAV_PATHS = [
  Routes.HOME,
  Routes.ANALYTICS,
  Routes.ANALYTICS_CODES,
  Routes.ANALYTICS_EVENTS,
  Routes.ANALYTICS_INSIGHTS,
  Routes.ANALYTICS_PAGE,
  Routes.ANALYTICS_PAGES,
  Routes.PAGE_EDITOR,
  Routes.RESERVE_SLUG,
  Routes.BUNDLES,
  Routes.BUNDLE_DETAILS,
  Routes.DASHBOARD,
  Routes.CODEE,
  Routes.CODE,
  Routes.CODES,
  Routes.CRM_SUBMISSIONS_PAGE,
  Routes.CRM_FORMS,
  Routes.CRM_FORMS_EDITOR,
  Routes.CRM_FORMS_SUBMISSIONS,
  Routes.EDIT_CODE,
  Routes.ACCOUNT,
  Routes.ACCOUNT_CHANGE_PLAN,
  Routes.FOLDERS,
  Routes.CREATE_FLOWCODE,
  Routes.STUDIO,
  Routes.STUDIO_COMPONENTS,
  Routes.EDIT_STUDIO,
  Routes.AUTO,
  Routes.REPORTS,
  Routes.REPORT,
  Routes.STUDIO_LIBRARY,
  Routes.STUDIO_TEMPLATES,
  Routes.PAGES,
  Routes.ORG,
  Routes.DOMAINS,
  Routes.MY_ORG_INVITATIONS,
  Routes.MY_ORG_MEMBERS,
  Routes.MY_ORG_TEAMS,
  Routes.MY_ORG_TEAM,
  Routes.TRASH,
  Routes.ARCHIVE_ASSETS,
  Routes.PAGE_TEMPLATE_EDITOR,
  Routes.TEMPLATES_DISCOVER,
  Routes.TEMPLATES_DISCOVER_CODES,
  Routes.TEMPLATES_DISCOVER_PAGES,
  Routes.TEMPLATES_YOUR_TEMPLATES,
  Routes.TEMPLATES_YOUR_CODES,
  Routes.TEMPLATES_YOUR_PAGES,
  Routes.TEMPLATES_YOUR_CREATE,
  Routes.ASSETS,
  Routes.INTERNAL_TOOLS,
  Routes.PIXEL_MANAGEMENT,
  Routes.SEARCH_RESULTS,
  Routes.ANALYTICS_CONVERSIONS,
  Routes.BRAND_KIT
]

export const LEFT_NAV_PATHS = [
  Routes.PAGE_EDITOR,
  Routes.UPGRADE,
  Routes.RESERVE_SLUG,
  Routes.DASHBOARD,
  Routes.CODES,
  Routes.EDIT_CODE,
  Routes.ACCOUNT,
  Routes.ACCOUNT_CHANGE_PLAN,
  Routes.FOLDERS,
  Routes.CREATE_FLOWCODE,
  Routes.STUDIO,
  Routes.STUDIO_COMPONENTS,
  Routes.EDIT_STUDIO,
  Routes.AUTO,
  Routes.STUDIO_LIBRARY,
  Routes.STUDIO_TEMPLATES,
  Routes.PAGES,
  Routes.ANALYTICS,
  Routes.ANALYTICS_CODES,
  Routes.ANALYTICS_PAGES,
  Routes.ANALYTICS_PAGE,
  Routes.ANALYTICS_EVENTS,
  Routes.ANALYTICS_INSIGHTS,
  Routes.ORG,
  Routes.REPORTS,
  Routes.REPORT,
  Routes.DOMAINS,
  Routes.PAGE_TEMPLATE_EDITOR,
  Routes.ASSETS,
  Routes.PIXEL_MANAGEMENT,
  Routes.SEARCH_RESULTS,
  Routes.ANALYTICS_CONVERSIONS
]

export const WHITE_NAV_VARIANT_PATHS = new Set([Routes.INDEX])

export const FLOWPAGE_PATHS = [Routes.PAGE_EDITOR, Routes.RESERVE_SLUG, Routes.CRM_FORMS_EDITOR]

export const EXCLUDED_MOBILE_SEARCH_PATHS = [Routes.CREATE_FLOWCODE]
export const PLATFORM_TOP_NAV_HEIGHT = 40
export const MOBILE_NAV_HEIGHT = 60
export const DESKTOP_NAV_HEIGHT = 71
export const NAV_SHORT_LINKS_BP = 1210
export const NAV_LOGGED_OUT_LINKS_BP = 1087
export const NAV_V2_LOGGED_OUT_LINKS_BP = 993

export const NAV_V2_HEIGHT = 56
export const SIDE_NAV_V2_DESKTOP_WIDTH = 196
export const FLOWPAGE_PROFILE_ACTION_BAR_HEIGHT = 58

//v3 nav measurements
export const MOBILE_BOTTOM_NAV_BAR_HEIGHT = 60
export const NAVIGATION_RAIL_WIDTH_V3 = 80
export const DESKTOP_SIDE_DRAWER_WIDTH_V3 = 256
export const DESKTOP_COLLAPSED_SIDE_DRAWER_EDGE_WIDTH_V3 = 32
export const DESKTOP_SIDE_DRAWER_TOGGLE_TRANSITION_SECONDS = 0.2
export const DESKTOP_TOP_NAV_V3_HEIGHT = 64
export const MOBILE_NAV_V3_HEIGHT = 64
export const MOBILE_NAV_V3_HEIGHT_WITH_CHIP = 108
export const DESKTOP_ADDITIONAL_LEFT_OFFSET_V3 = 48

export const SCROLL_MARGIN_TOP = NAV_V2_HEIGHT + 10
