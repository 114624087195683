import { fireAnalyticsEvent } from '../helpers/fireAnalyticsEvent'
import { useEffect } from 'react'

export const useHeapTranslateTracking = (): void => {
  useEffect(() => {
    const detectedLanguage = document.getElementsByTagName('html')[0].getAttribute('lang')
    if (detectedLanguage) {
      if (!detectedLanguage.includes('en'))
        fireAnalyticsEvent('Activation_Application_Is_Translated', {
          detectedLanguage
        })
    }
  }, [])
}
